import { jsx } from "theme-ui"
import React from "react"
import theme from "../gatsby-plugin-theme-ui/index"
import Container from "../components/container"
import Text from "../components/text"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import RichText from "./rich-text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "./wildfireaccordian.css"

const FAQModule = ({ title, description, contentCards }) => {
  const renderAccordionItem = data => {
    return (
      <React.Fragment>
        {data &&
          data.map((ele, index) => {
            return (
              <AccordionItem key={index}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {ele.name ? ele.name : ""}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="font-helvetica">
                    {/* <RichText data={ele.quote} /> */}
                    {documentToReactComponents(ele.quote.json)}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
      </React.Fragment>
    )
  }

  return (
    <Container>
      {title && (
        <Text
          type="h2"
          customStyle={{
            my: theme => [
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
              theme.spacing.vertical.sm,
            ],
          }}
        >
          {title}
        </Text>
      )}
      {description && <RichText data={description} />}
      <div className="accordionCustom">
        <Accordion
          allowZeroExpanded="true"
          style={{ fontFamily: "Helvetica Neue,sans-serif" }}
          key={1}
        >
          {contentCards &&
            contentCards.map((ele, index) => {
              return (
                <React.Fragment>
                  {/* {ele.title && <h2 key={index} style={{ paddingTop: index > 0 ? '50px' : '0px', fontFamily: 'Helvetica Neue,sans-serif' }} >{ele.title} </h2> } */}
                  {ele.topics &&
                    renderAccordionItem(ele.topics ? ele.topics : null)}
                </React.Fragment>
              )
            })}
        </Accordion>
      </div>
    </Container>
  )
}

export default FAQModule
