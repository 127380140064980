/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import get from "lodash/get"

import { findBySlug, renderLineBreak } from "../helpers"

import AdvantagesModule from "../components/advantages-module"
import BenefitsModule from "../components/benefits-module"
import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectGalleryModule from "../components/project-gallery-module"
import ProjectUnique from "../components/project-unique"
import ProcessModule from "../components/process-module"
import TestimonialsModule from "../components/testimonials-module"
import { paramsCheck } from "../functions/functions"
import FAQModule from "../components/wildfire-faq-module"
import Container from "../components/container"
import Column from "../components/column"
import Row from "../components/row"
import Text from "../components/text"
import CTA from "../components/cta"
import RichText from "../components/rich-text"

const WildFireRebuild = ({ data }) => {
  paramsCheck()
  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }

    const ctaList = [
      {
        text: heroBladePatternData.ctaTitle,
        type: "secondary",
        link: heroBladePatternData.ctaDestination || "/",
      },
    ]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
 
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "architects-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderProcessModule = () => {
    const processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-process-text-module",
    })

    if (!processTextModuleBladePatternData) return
    processTextModuleBladePatternData.isLastColumnCta =  true;
    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-testimonials-module",
    })
    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderAdvantagesModule = () => {
    const advantagesBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-advantages-module",
    })
    if (!advantagesBladePatternData) return

    return <AdvantagesModule bladePatternData={advantagesBladePatternData} />
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        projectsData={data.allContentfulProject}
        customStyle={{ pb: "61%", mx: 15, height: 0 }}
      />
    )
  }

  const renderFAQModule = () => {
    const faqModuleSectionList = findBySlug({
      list: data.contentfulPage.sectionList,
      slug: "frequently-asked-questions",
    })

    const faqModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-faq-module",
    })

    if (!faqModuleBladePatternData) return null

    const description = get(
      faqModuleBladePatternData,
      "contentCards[0].description"
    )

    return (
      <div
        sx={{
          mt: theme => [theme.spacing.vertical.md],
          //   theme.spacing.vertical.md,
          //   theme.spacing.vertical.lg
          // ]
        }}
      >
        <FAQModule
          title={faqModuleBladePatternData.title}
          description={description}
          contentCards={faqModuleSectionList.contentCards}
        />
      </div>
    )
  }

  const renderEachProjectIsUniqueBladePattern = () => {
    const eachProjectIsUniqueBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-each-project-is-unique",
    })
    if (!eachProjectIsUniqueBladePatternData) return 

    return (
      <ProjectUnique data={eachProjectIsUniqueBladePatternData}></ProjectUnique>
    )
  }

  
  const renderTogetherModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "wildfire-rebuild-together-module",
    })
    if (!bladePatternData) return

    return (
      <div
        sx={{
          py: theme => theme.spacing.vertical.lg,
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
      >
        <Container>
          <Row>
            <Column size={[12, 6, 6]}>
              <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
              <br />
              {bladePatternData.description ? (
                <Text customStyle={{ fontSize: 17 }}>{bladePatternData.description.description}</Text>
              ) : null}
              {bladePatternData.contentCards.map((basicCard, idx) => (
                <Row
                  key={idx}
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    "p:last-of-type": {
                      mb: 0,
                    },
                    ml: '5px',
                  }}
                >
                  <Text type="h3">{basicCard.title}</Text>
                  {basicCard.description ? (
                    <div>
                      <RichText
                        data={basicCard.description}
                        textStyle={
                          !basicCard.title
                            ? {
                              mt: "-10px !important",
                              fontSize: 17
                            }
                            : { fontSize: 17 }
                        }
                      />
                    </div>
                  ) : null}
                  {basicCard.ctaTitle ? (
                    <CTA
                      customStyle={{
                        mt: 20,
                      }}
                      link={
                        (basicCard.ctaDestination &&
                          basicCard.ctaDestination.ctaDestination) ||
                        (basicCard.ctaDestinationExternal &&
                          basicCard.ctaDestinationExternal
                            .ctaDestinationExternal) ||
                        "/"
                      }
                      type={"secondary"}
                      linkType={
                        basicCard.ctaDestinationExternal
                          ? "external"
                          : "internal"
                      }
                    >
                      {basicCard.ctaTitle}
                    </CTA>
                  ) : null}
                </Row>
              ))}
              {bladePatternData.ctaTitle ? (
                <div sx={{ mt: theme => [theme.spacing.vertical.sm] }}>
                  <CTA
                    type="secondary"
                    link={get(bladePatternData, "ctaDestination", "/")}
                    linkType="internal"
                  >
                    {bladePatternData.ctaTitle}
                  </CTA>
                </div>
              ) : null}
            </Column>
            <Column size={[12, 6, 6]}>
              {bladePatternData.desktopImage ? (
                <img
                  alt=""
                  src={get(bladePatternData, "desktopImage.file.url")}
                  sx={{
                    mt: theme => [theme.spacing.vertical.md, 0],
                  }}
                />
              ) : null}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderTogetherModule()}
      {renderEachProjectIsUniqueBladePattern()}
      {renderServicesModule()}
      {renderProcessModule()}
      {renderProjectGalleryModule()}
      {/* {renderFAQModule()} */}
      {/* {renderTestimonialsModule()} */}
      {renderAdvantagesModule()}
      {renderCtaModule()}
    </Page>
  )
}

export default WildFireRebuild

export const query = graphql`
  query wildfireRebuildPageQuery {
    contentfulPage(slug: { eq: "wildfire_help" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
      }
    }
  }
`
